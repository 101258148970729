import React from "react";
import s from "./Default.module.css"
import ToNews from "../News/ToNews";
import img from "../../assets/News/News020425.jpg"

const News020425 = () => {

    return <div className={s.page}>
        <h2 style={{
            fontWeight: "normal",
            marginTop: "50px",
            color: "#3a4663",
            textAlign: "left",
            maxWidth: "1100px",
            margin: "40px auto",
            fontFamily: "Proxima Nova Lt"
        }}>
            Заседание Экспертного совета по развитию служб медиации и (или) примирения в образовательных организациях Минпросвещения России
        </h2>
        <div>
            <img src={img} />
        </div>
        <div style={{ fontFamily: "Proxima Nova Lt" }}>
            1 апреля 2025 года состоялось заседание Экспертного совета по развитию служб медиации и (или) примирения в образовательных организациях Минпросвещения России.
            <p></p>
            На заседании были обсуждены основные направления деятельности Экспертного совета в 2025 году, вопросы подготовки и проведения Всероссийского совещания школьных служб примирения и медиации, территориальные служб медиации и (или) примирения, а также форматы работы службы медиации и (или) примирения по предупреждению и решению конфликтных и спорных ситуаций, связанных с несовершеннолетними в рамках развития системы наставничества.
            <ul>
                С докладами выступили:
                <li>директор Департамента государственной политики в сфере защиты прав детей Минпросвещения России, председатель Экспертного совета Фальковская Лариса Павловна</li>
                <li>руководитель Городской службы примирения, старший методист государственного бюджетного учреждения города Москвы «Городской психолого-педагогический центр Департамента образования и науки города Москвы», сопредседатель Экспертного совета Коновалов Антон Юрьевич</li>
                <li>руководитель Центра примирительных технологий и общественного взаимодействия Института непрерывного профессионального образования «Вектор развития» федерального государственного бюджетного образовательного учреждения высшего образования «Башкирский государственный педагогический университет им. М. Акмуллы» Беляев Максим Анатольевич</li>
                <li>начальник отдела Департамента по взаимодействию с федеральными органами исполнительной власти Общероссийского общественно-государственного движения детей и молодежи «Движение первых» Булавкина Ольга Васильевна.</li>
            </ul>
            Экспертный совет по развитию служб медиации и (или) примирения в образовательных организациях создан в целях содействия совершенствованию деятельности служб медиации и примирения в образовательных организациях, и является постоянно действующим консультативно-совещательным органом при Минпросвещения России.
            <p></p>
        </div>
        <ToNews />
    </div>
}


export default News020425;